
export const PAGE_ROUTES = {
  HOME: "/",
  ELEVATION_S: "/elevation-s",
  ELEVATION_G: "/elevation-g",
  CALISTO: "/calisto",
  SOFTWARE: "/software",
  ABOUT: "/about",
  RESOURCES: "/resources",
  CONTACT: "/contact",
};

export const LOADING_STATE = {
  NOT_LOADING: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};