/* eslint-disable react-hooks/exhaustive-deps */
import "./industriesCarousel.css";

import React from "react";


const Testimonials = ({ slides, head="Industries" }) => {

  const [index, setIndex] = React.useState(0);

  return (
    <React.Fragment>
      <h2 className={"section-head"}>{head}</h2>

      <div className={"industries-cont"}>
        {
          slides.map((slide, i) => (
            <div
              key={i}
              className={["industry-block standard-box", (i === index) ? "active" : ""].join(" ")}
              onClick={() => setIndex(i)}
            >
              <img src={slide.img} />
              <p className={"industry-head"}>{slide.head}</p>
              <p className={"industry-description"}>{slide.description}</p>
            </div>
          ))
        }
      </div>
    </React.Fragment>
  );
};

export default Testimonials;