/* eslint-disable react-hooks/exhaustive-deps */
import "./backgroundEffect.css";

import React from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const BackgroundEffect = () => {

  const [ init, setInit ] = React.useState(false);

  // this should be run only once per application lifetime
  React.useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  
  return (
    <React.Fragment>
      {
        (init) && (
          <Particles
            id={"tsparticles"}
            className={"background-effect"}
            options={{
              background: {
                color: {
                  value: "transparent",
                },
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onHover: {
                    enable: true,
                    mode: "bubble",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 200,
                    duration: 2,
                    opacity: 1,
                    size: 30,
                  },
                },
              },
              particles: {
                color: {
                  value: "#fc2619",
                },
                move: {
                  direction: "right",
                  enable: true,
                  outModes: {
                    default: "out",
                  },
                  random: true,
                  speed: 2,
                  straight: false,
                },
                collisions: {
                  enable: true,
                  mode: "bounce",
                  maxSpeed: 1,
                },
                number: {
                  density: {
                    enable: true,
                  },
                  value: 200,
                },
                opacity: {
                  value: 0,
                },
                //Set up the shape to be an empty square
                shape: {
                  type: "image",
                  options:{
                    image: [
                      {
                        src: "/images/bg-particle.png",
                        width: 12,
                        height: 12,
                      },
                    ],
                  },
                },
                size: {
                  value: { min: 12, max: 12 },
                },
              },
              detectRetina: true,
            }}
          />
        )
      }
      {/* Background grid lines */}
      {/* <div className={"background-effect-grid"}>
        {
          [...Array(10)].map((_, i) => (
            <div
              key={i}
              className={"background-effect-line"}
            />
          ))
        }
      </div> */}
    </React.Fragment>
  );
};

export default BackgroundEffect;