import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fc2619",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Quantico",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
      "@media (max-width:800px)": {
        fontSize: "0.8em",
      },
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: "#fff",
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiButton: {
      contained: {
        "&:disabled": {
          color: "#ccc",
        },
      },
    },
    // Menu background color
    MuiPaper: {
      root: {
        backgroundColor: "#06335c",
      },
    },
  },
});

export default theme;