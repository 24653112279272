/* eslint-disable react-hooks/exhaustive-deps */
import "./pageTransition.css";

import React from "react";
import { useLocation } from "react-router-dom";

const PageTransition = () => {
  const { pathname } = useLocation();
  const [transitionClass, setTransitionClass] = React.useState("");
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setTransitionClass("open");
    setTimeout(() => {
      setTransitionClass("close");
    }, 800);
    setTimeout(() => {
      setTransitionClass("");
    }, 1600);
  }, [pathname]);

  return (
    <div className={["page-transition", transitionClass].join(" ")} />
  );
};

export default PageTransition;