import "./blip.css";

import React from "react";

/* ======================================== COMPONENT ======================================== */

const Blip = ({ head, text, top=true, right=true, x, y, altColor=false, number=null }) => {
  return (
    <div
      className={`blip-cont ${(altColor) ? "alt-color" : ""}`}
      style={{ top: y, left: x }}
    >
      <div className={"blip"} />
      <div className={"blip-outer"} />
      {
        (number) && (
          <p className={"blip-number"}>{number}</p>
        )
      }
      <div className={["blip-line-vertical", top ? "top" : "bottom"].join(" ")} />
      <div className={["blip-line-horizontal", right ? "right" : "left", top ? "top" : "bottom"].join(" ")} />
      <div className={["blip-text standard-box", right ? "right" : "left", top ? "top" : "bottom"].join(" ")}>
        <h3>{head}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Blip;