import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import MuiTheme from "./muiTheme";
import App from "./components/App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(
  <BrowserRouter basename={baseUrl}>
    <MuiThemeProvider theme={MuiTheme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
);