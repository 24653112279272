/* eslint-disable react-hooks/exhaustive-deps */
import "./drones.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import StandardButton from "../general/StandardButton";
import BackgroundEffect from "../general/BackgroundEffect";
import IndustriesCarousel from "../home/IndustriesCarousel";
import Blip from "./Blip";
import ComponentBlock from "./ComponentBlock";


const INTRO_GAL = [
  {
    img: "/images/drones/elevation-g/gal-4.jpg",
    label: "SilentFly™ Technology",
  },
  {
    img: "/images/drones/elevation-g/gal-5.jpg",
    label: "Unmatched Efficiency",
  },
  {
    img: "/images/drones/elevation-g/gal-6.jpg",
    label: "Meticulously Designed",
  },
];

const BOX_ITEMS = [
  {
    head: "Your Elevation-G",
    description: "Fuselage, inner wings & tail, gimbal payload, and 2x outer wings",
    thumb: "/images/drones/elevation-g/box-thumb-1.png",
    image: "/images/drones/elevation-g/box-image-1.png",
  },
  {
    head: "Transport case",
    description: "Lightweight transport container or backpack case for easy transport and storage",
    thumb: "/images/drones/elevation-g/box-thumb-5.png",
    image: "/images/drones/elevation-g/box-image-5.png",
  },
  {
    head: "Ground control",
    description: "Herelink V1.1 with Elevation mission planning software",
    thumb: "/images/drones/elevation-g/box-thumb-4.png",
    image: "/images/drones/elevation-g/box-image-10.png",
  },
  {
    head: "Manual controller",
    description: "Herelink GCS controller for manual flight control and payload operation",
    thumb: "/images/drones/elevation-g/box-thumb-6.png",
    image: "/images/drones/elevation-g/box-image-7.png",
  },
  {
    head: "Battery",
    description: "Lithium ion battery pack and battery charger unit",
    thumb: "/images/drones/elevation-g/box-thumb-2.png",
    image: "/images/drones/elevation-g/box-image-6.png",
  },
  {
    head: "Extras",
    description: "Anemometer, spare landing struts, spare propellers, operating manual",
    thumb: "/images/drones/elevation-g/box-thumb-3.png",
    image: "/images/drones/elevation-g/box-image-8.png",
  },
];


const ElevationG = () => {

  //Set up intro gallery
  const [galIndex, setGalIndex] = React.useState(0);
  //Auto rotate images
  const [intervalItem, setIntervalItem] = React.useState(null);
  React.useEffect(() => {
    let intervalLength = 5000;
    if (intervalItem) {
      clearInterval(intervalItem);
    }
    else {
      //First or manually set, longer interval
      intervalLength = 10000;
    }
    setIntervalItem(setInterval(() => {
      setGalIndex((galIndex + 1) % INTRO_GAL.length);
    }, intervalLength));
    return () => {
      clearInterval(intervalItem);
    };
  }, [galIndex]);

  //Clear interval when manually changing image
  const manuallyChangeImage = (i) => {
    clearInterval(intervalItem);
    setIntervalItem(null);
    setGalIndex(i);
  };

  //Set up box section
  const [boxView, setBoxView] = React.useState(0);
  const setBoxViewCB = (newBoxView) => (
    () => {
      setBoxView(newBoxView);
    }
  );
  //Preload the box images
  React.useEffect(() => {
    BOX_ITEMS.forEach((boxItem) => {
      const img = new Image();
      img.src = boxItem.image;
    });
  }, []);


  return (
    <main>
      <Helmet>
        <title>Elevation-G | Avior Labs</title>
        <meta
          name={"description"}
          content={"Elevation-G is a silent surveillance drone with a 90-minute flight time and thermal & RGB cameras."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"drone-header"}>
        <h1>ELEVATION-<span>G</span></h1>
        
        <div className={"drone-blips-cont standard-width"}>
          <img
            className={"drone-blips-img"}
            src={"/images/drones/elevation-g/elevation-g-blips-1.jpg"}
          />
          <Blip
            head={"Detachable wings"}
            text={"2.54m  wingspan with detachable outer wings for transportability and ease of assembly."}
            x={"13%"}
            y={"32%"}
            right={true}
            top={true}
          />
          <Blip
            head={">90 mins useful flight"}
            text={"Fully electric with 90 minutes useful flight time in its standard configuration."}
            x={"53%"}
            y={"33%"}
            right={false}
            top={true}
          />
          <Blip
            head={"Convenient to transport"}
            text={"Light and packages in a convenient case that fits in the boot of a sedan car."}
            x={"72%"}
            y={"63%"}
            right={false}
            top={false}
          />
          <Blip
            head={"Thermal & RGB cameras"}
            text={"Comes equipped with a 2-axis gimbal with thermal and RGB cameras."}
            x={"36%"}
            y={"55%"}
            right={true}
            top={false}
          />
          <Blip
            head={"Patented propeller config"}
            text={"Single tilting propeller on the nose offers flight efficiency and a low noise profile."}
            x={"24%"}
            y={"43%"}
            right={true}
            top={false}
          />
        </div>
      </header>

      <section className={"drone-intro-section standard-width"}>
        <h2 className={"section-head"}>
          Silent surveillance<br />with Elevation-G
        </h2>
        <div className={"drone-intro-gal"}>
          <div className={"drone-intro-gal-main-cont"}>
            {
              INTRO_GAL.map((gal, i) => (
                <img
                  className={`drone-intro-gal-main standard-box ${galIndex === i ? "active" : ""}`}
                  src={gal.img}
                  key={i}
                />
              ))
            }
          </div>
          <div className={"drone-intro-gal-thumbs"}>
            <h3>{INTRO_GAL[galIndex].label}</h3>
            {
              INTRO_GAL.map((gal, i) => (
                <div
                  className={`drone-intro-gal-thumb-cont ${galIndex === i ? "active" : ""}`}
                  onClick={() => manuallyChangeImage(i)}
                  key={i}
                >
                  <img
                    className={"drone-intro-gal-thumb standard-box"}
                    src={gal.img}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className={"drone-features-section"}>
        <img
          className={"drone-features-bg"}
          src={"/images/drones/elevation-g/features-bg5.jpg"}
        />
        <div className={"drone-features-bg-color"} />

        <div className={"drone-features-content standard-width"}>
          <p className={"section-pre-head"}>Key features</p>
          <h2 className={"section-head"}>Durable & dynamic</h2>

          <div className={"drone-features-blocks"}>
            <div className={"drone-features-block standard-box"}>
              <h3>SilentFly™ technology</h3>
              <div className={"divider small"} />
              <p>
                At Avior Labs, we understand the importance of minimising the impact of our drones on the
                environment and the people and animals around them. Elevation-G makes use of a unique and
                patented VTOL configuration, where only one of its four motors is tilted forward for conventional
                flight. This configuration provides a low noise profile that is significantly lower than that of
                other drones in its class. 
              </p>
            </div>
            <div className={"drone-features-block standard-box"}>
              <h3>Accurate Surveillance</h3>
              <div className={"divider small"} />
              <p>
                By equipping Elevation-G with a 2-axis gimbal, thermal and RGB camera pair, we ensure that you
                are able to capture high-quality and accurate surveillance footage, no matter the conditions.
                Together with the exceptional range and flight time, Elevation-G is the perfect tool for surveillance
                operations.
              </p>
            </div>
            
            <div className={"drone-features-block standard-box"}>
              <h3>Exceptional efficiency</h3>
              <div className={"divider small"} />
              <p>
                Elevation-G makes use of a high aspect ratio wing and tractor propeller which allows us to maximise
                the aerodynamic efficiency and thus decrease the energy required to fly. The forward swept wings are
                a function of our unique configuration and provides for forgiving flight characteristics. The Elevation-G airframes are
                equipped with optimised embedded antenna technology that provides for exceptional control range.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={"drone-payloads-section standard-width"}>
        <h2 className={"section-head"}>Payload</h2>
        <div className={"divider"} />
        <ComponentBlock
          image={"/images/drones/elevation-g/payloads-5.png"}
          head={"NextVision DragonEye 2 on 2-axis gimbal"}
          text={"DragonEye2 is a dual EO-IR stabilized camera offering unparalleled long range observation capability. The camera is equipped with a 2-axis gimbal for stabilization and can be controlled remotely from the ground station."}
          linkText={"View product (external link)"}
          link={"https://www.nextvision-sys.com/dragoneye-2/"}
          altStyle={true}
        />
      </section>

      <section className={"drone-specs-section"}>
        <div className={"drone-specs-bg"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Specifications</h2>

          <div className={"drone-specs-tables-cont"}>
            <table className={"drone-specs-table standard-box"}>
              <tbody>
                <tr>
                  <td>Wingspan</td>
                  <td>2.54 m</td>
                </tr>
                <tr>
                  <td>MTOW</td>
                  <td>4.6 kg</td>
                </tr>
                <tr>
                  <td>Endurance</td>
                  <td>90 mins</td>
                </tr>
                <tr>
                  <td>Best endurance airspeed</td>
                  <td>15 m/s</td>
                </tr>
              </tbody>
            </table>
            <table className={"drone-specs-table standard-box"}>
              <tbody>
                <tr>
                  <td>Best range airspeed</td>
                  <td>18 m/s</td>
                </tr>
                <tr>
                  <td>Wind tolerance in hover</td>
                  <td>8 m/s</td>
                </tr>
                <tr>
                  <td>Streaming & control range</td>
                  <td>10-15 km</td>
                </tr>
                <tr>
                  <td>Maximum operating altitude</td>
                  <td>6500 ft</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className={"drone-industries standard-width"}>
        <IndustriesCarousel
          slides={[
            {
              head: "Border Security",
              img: "/images/home/industry6-1.jpg",
              description: "Surveillance drones enhance border security by providing real-time aerial monitoring, allowing for quick detection and response to unauthorized crossings. Elevation-G can cover vast and difficult terrains more efficiently than ground patrols, reducing the need for extensive manpower and enabling quicker response times.",
            },
            {
              head: "Infrastructure Security",
              img: "/images/home/industry7.jpg",
              description: "Elevation-G can provide continuous 90-min monitoring of critical assets like pipelines and power lines, assisting in identifying potential threats or damages in real time. A combination of more than one Elevation-G operated on site can offer a prolonged uninterrupted surveillance.",
            },
            {
              head: "Search and Rescue",
              img: "/images/home/industry8.jpg",
              description: "Surveillance VTOL drones are crucial in search and rescue operations as they can quickly cover large areas, providing real-time visuals to locate missing persons more efficiently. The stable and clear footage provided by Elevation-G, which can also reach areas that would usually be difficult to access, can be invaluable in these high-pressure situations.",
            },
            {
              head: "Disaster Monitoring",
              img: "/images/home/industry9.jpg",
              description: "Drones play a vital role in disaster monitoring and recovery operations by providing rapid aerial assessments of affected areas, helping to identify the extent of damage and prioritize response efforts. The endurance and range of Elevation-G makes it ideal for these missions.",
            },
            {
              head: "Anti-poaching",
              img: "/images/home/industry10.jpg",
              description: "With wildlife poaching remaining an acute challenge in sub-Saharan Africa, the addition of Elevation-G to anti-poaching units can improve the protection efforts. Elevation-G can be used to track, locate and monitor poachers day or night.",
            },
          ]}
        />
      </section>

      <section className={"drone-box-section"}>
        <div className={"drone-box-bg-color"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>What's in the box?</h2>

          <div className={"drone-box-cont"}>
            <div
              className={"drone-box-items"}
            >
              {
                BOX_ITEMS.map((boxItem, i) => {
                  if (i % 2 === 0) {
                    return (
                      <div
                        key={i}
                        className={["db-item-cont", (boxView === i) ? "active" : ""].join(" ")}
                        onClick={setBoxViewCB(i)}
                      >
                        <div className={"db-item-img"}>
                          <img src={boxItem.thumb} />
                        </div>
                        <div className={"db-item-text"}>
                          <h3>{boxItem.head}</h3>
                          <p>{boxItem.description}</p>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          

            <div className={"drone-box-view"}>
              {
                BOX_ITEMS.map((boxItem, i) => (
                  <img
                    key={i}
                    className={(boxView === i) ? "active" : ""}
                    src={boxItem.image}
                  />
                ))
              }
            </div>

            <div
              className={"drone-box-items"}
            >
              {
                BOX_ITEMS.map((boxItem, i) => {
                  if (i % 2 === 1) {
                    return (
                      <div
                        key={i}
                        className={["db-item-cont", (boxView === i) ? "active" : ""].join(" ")}
                        onClick={setBoxViewCB(i)}
                      >
                        <div className={"db-item-img"}>
                          <img src={boxItem.thumb} />
                        </div>
                        <div className={"db-item-text"}>
                          <h3>{boxItem.head}</h3>
                          <p>{boxItem.description}</p>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
      </section>

      {/* ================================== CTA ================================== */}
      <section className={"drone-cta-section standard-width"}>
        <p className={"section-head-pre"}>Get started</p>
        <h2 className={"section-head"}>Rise above with Elevation-G</h2>
        <p className={"drone-cta-text"}>
          Get started today with Elevation-G. Whether you're looking to rent, rent to own, or buy, our support team 
          is here to help you get the most out of your drone. Contact us today to learn more or organise a demo.
        </p>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Rent<br />(coming soon)"}
            className={"drone-cta-button"}
          />
        </Link>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Rent to own"}
            className={"drone-cta-button"}
          />
        </Link>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Buy"}
            className={"drone-cta-button"}
          />
        </Link>
        <img
          className={"drone-cta-img standard-box"}
          src={"/images/drones/elevation-g/contact-elevation-g.jpg"}
        />
      </section>
    </main>
  );
};

export default ElevationG;