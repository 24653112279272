import "./componentBlock.css";

import React from "react";

/* ======================================== COMPONENT ======================================== */

const ComponentBlock = ({ image, head, text, altStyle, linkText=null, link=null }) => {
  return (
    <div className={`component-block standard-box ${altStyle ? "alt" : ""}`}>
      <div className={"component-block-bg"} />
      <div className={"component-block-bg-border"} />
      {
        (altStyle) && (
          <img
            className={"component-block-img"}
            src={image}
          />
        )
      }
      <div className={"component-block-content"}>
        <h3>{head}</h3>
        <p>{text}</p>
        {
          (link !== null) && (
            <a
              href={link}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {linkText}
            </a>
          )
        }
      </div>
      {
        (!altStyle) && (
          <img
            className={"component-block-img"}
            src={image}
          />
        )
      }
    </div>
  );
};

export default ComponentBlock;