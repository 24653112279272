import "./consentBanner.css";

import React from "react";
import { Button } from "@material-ui/core";

import { loadSavedConsent, saveConsentPreferences, gtagSetup } from "./localStorage";

const COOKIE_OPTIONS = {
  ANALYTICS: false,
};
export const PP_LINK = "/images/Privacy_notice.pdf";
export const MANUAL_LINK = "/images/POPIA_manual.pdf";

const saveAndInitPreferences = (consentPreferences) => {
  saveConsentPreferences(consentPreferences);
  if (COOKIE_OPTIONS.ANALYTICS && consentPreferences.analyticsConsent) {
    gtagSetup();
  }
};

class ConsentBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consentPreferences: {},
      diagOpen: false,
    };
    this._acceptAll = this._acceptAll.bind(this);
    this._rejectAll = this._rejectAll.bind(this);
  }

  componentDidMount() {
    //Init the state here since this will execute client-side only (not during ssr)
    const consentPreferences = loadSavedConsent();
    this.setState({
      consentPreferences: (consentPreferences !== null) ? consentPreferences : {
        analyticsConsent: false,
      },
      bannerOpen: (consentPreferences === null),
    });

    //Start analytics if it is already enabled
    if (COOKIE_OPTIONS.ANALYTICS && (consentPreferences !== null) && consentPreferences.analyticsConsent) {
      gtagSetup();
    }
  }

  _acceptAll() {
    const consentPreferences = {
      analyticsConsent: true,
    };
    saveAndInitPreferences(consentPreferences);

    this.setState({
      bannerOpen: false,
      consentPreferences,
    });
  }

  _rejectAll() {
    const consentPreferences = {
      analyticsConsent: false,
    };
    saveAndInitPreferences(consentPreferences);

    this.setState({
      bannerOpen: false,
      consentPreferences,
    });
  }

  render() {
    const { bannerOpen } = this.state;

    let banner;
    if (bannerOpen) {
      banner = <div className={"consent-banner"}>
        <div className={"consent-text"}>
          <h3>We use cookies</h3>
          <p>
            We use cookies and other tracking technologies to improve your browsing experience on our website,
            to analyze our website traffic, and to allow for better customer support.
            By continuing your use of the website, you are expressly consenting to the
            processing of your personal information as per our privacy policy.
            View our
            {" "}
            <a
              href={PP_LINK}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Privacy Policy
            </a>
            {" "}
            and
            {" "}
            <a
              href={MANUAL_LINK}
              target={"_blank"}
              rel={"noreferrer"}
            >
              POPIA Manual
            </a>
          </p>
        </div>
        <div className={"consent-buttons"}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={this._acceptAll}
          >
            Accept All
          </Button>
          <span
            className={"consent-reject"}
            onClick={this._rejectAll}
          >
            Disable Cookies
          </span>
        </div>
      </div>;
    }

    return (
      <div style={{ zIndex: 9999 }}>
        {bannerOpen ? banner : null}
      </div>
    );
  }
}

export default ConsentBanner;