import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import StandardButton from "../general/StandardButton";
import BackgroundEffect from "../general/BackgroundEffect";


const Calisto = () => {

  return (
    <main>
      <Helmet>
        <title>Callisto | Avior Labs</title>
        <meta
          name={"description"}
          content={"Callisto is our next-generation, long-endurance VTOL drone. It is currently in early development and we expect to roll out a prototype in late 2025 or early 2026."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"drone-image-header"}>
        <h1>CALLISTO</h1>
        <img src={"/images/drones/callisto/callisto-bg.jpg"} />
      </header>

      <section className={"home-intro-1 standard-width"}>
        <h2 className={"section-head"}>See beyond<br />the horizon</h2>
        <p className={"home-intro-1-text"}>
          Calllisto is our next-generation, long-endurance VTOL drone. It is currently in early development
          and we expect to roll out a prototype in late 2025 or early 2026.
        </p>
      </section>

      <section className={"calisto-explain-section"}>
        <div className={"calisto-explain-bg"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Harnessing AI</h2>
          <div className={"calisto-explain-cont"}>
            <p>
              What sets Callisto apart from similar UAVs is that we are developing it from the outset with a strong embedded AI
              focus. Callisto is meant as a “force multiplier”, that will allow a small team to operate one or many airframes over
              a vast area when doing surveys or along vast distance when providing protection along long stretches of linear
              infrastructure. The AI will have two main responsibilities:
            </p>
            <ul className={"callisto-explain-points"}>
              <li>&#8226; Conduct the majority of the flying and systems monitoring tasks, requiring minimal human oversight.</li>
              <li>&#8226; Critically analyse all sensor information and only provide the mission specialists on the ground with the information that they require, when they require it. “No more staring at a video screen for hours on end”.</li>
            </ul>
            <img
              className={"calisto-explain-img standard-box"}
              src={"/images/drones/callisto/callisto-explain.jpg"}
            />
          </div>

          <h2 className={"section-head"}>Specifications</h2>
          <div className={"calisto-explain-cont"}>
            <p>
              Callisto will, once again, be aimed at both survey and surveillance applications:
            </p>
            <ul className={"callisto-explain-points"}>
              <li>&#8226; Surveing tracks of land in excess of 2000 ha at a time, such as game counting, wildlife management or vegetation surveys over entire game reserves.</li>
              <li>&#8226; Border patrol, inshore coastal patrol, maritime patrol, etc.</li>
              <li>&#8226; Extended linear infrastructure protection, such as pipeline, powerline and railway line monitoring and security.</li>
            </ul>
            <p>
              Type: Fixed wing VTOL with hybrid propulsion (electric VTOL + internal combustion powered sustaining engine) or hydrogen fuel cell powered.
            </p>
            
            <div className={"drone-specs-tables-cont callisto-table-cont"}>
              <table className={"drone-specs-table callisto-table standard-box"}>
                <tbody>
                  <tr>
                    <td>Ceiling</td>
                    <td>10,000 ft</td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>±25 kg, including a 4 kg payload</td>
                  </tr>
                  <tr>
                    <td>Design speeds</td>
                    <td>
                      <ul>
                        <li>Loiter 65 km/h</li>
                        <li>Cruise 80 km/h</li>
                        <li>Dash 100 km/h</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Sensors</td>
                    <td>
                      <ul>
                        <li>Sense-and-avoid system</li>
                        <li>Long range terrain detection LiDAR</li>
                        <li>Other sensors as per client requirements</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={"drone-specs-table callisto-table standard-box"}>
                <tbody>
                  <tr>
                    <td>Duration</td>
                    <td>10 hours</td>
                  </tr>
                  <tr>
                    <td>Wingpsan</td>
                    <td>±5 m</td>
                  </tr>
                  <tr>
                    <td>Robustness</td>
                    <td>
                      <ul>
                        <li>Operate in surface winds up to 12 m/s</li>
                        <li>Gust resistant</li>
                        <li>Can tolerate rain and dust</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Other systems</td>
                    <td>
                      <ul>
                        <li>Redundant GNSS units</li>
                        <li>Onboard AI-driven mission computer</li>
                        <li>Long range meshed datalink</li>
                        <li>Optional next generation satellite link</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* ================================== CTA ================================== */}
      <section className={"drone-cta-section standard-width"}>
        <p className={"section-head-pre"}>Chat with us</p>
        <h2 className={"section-head"}>Let Callisto expand your horizons</h2>
        <p className={"drone-cta-text"}>
          Get in touch with us today. Once our team fully understands your requirements,
          we can help prepare your business & team for a future with Callisto.
        </p>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Enquire now"}
            className={"drone-cta-button"}
          />
        </Link>
        <img
          className={"drone-cta-img standard-box"}
          src={"/images/drones/callisto/Callisto_Swarm.jpg"}
        />
      </section>
    </main>
  );
};

export default Calisto;