import "whatwg-fetch";
/*
*  Utilities used to make API calls
*/


/* Used to check the HTTP return status for error codes
*  If it find an error code it will throw the error
*  Otherwise it will propogate the response
*/
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

/* Performs a post request with the provided url and data. Returns the JSON response
*  Returns a promise that will resolve in one of the following:
*  - the success handler if the api call succeeds
*  - the error handler if the api call fails
*/
export const postRequest = (url, data, successHandler, errorHandler, afToken=null) => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (afToken !== null) {
    headers["RequestVerificationToken"] = afToken;
  }

  return fetch(url, {
    method: "POST",
    credentials: "same-origin", //This ensures cookies get sent
    body: JSON.stringify(data),
    headers,
  })
    .then(checkStatus)
    .then(
      //Success
      (response) => {
        return response.json().then(
          (jsonData) => () => successHandler(jsonData), //Could parse json
          () => successHandler,  //Could not parse json
        );
      },
      //Failure
      () => errorHandler,
    )
    .then((callback) => callback());
};