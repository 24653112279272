/*
  This file handles all aspects of saving the session to localStorage,
*/

import { postRequest } from "./apiHandlers";

/* ------------------------ LOCAL STORAGE - CONSENT ------------------------ */
const localStorage = window.localStorage;

const lsConsent = "cookieConsent";
const lsConsentTS = "consentTS";
const lsConsentVName = "consentVersion";
const lsConsentVersion = "1.0";
const lsConsentSessionIDs = "consentSessions";

const uniqueID = () => (
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  })
);

//This function loads the saved consent preferences from localstorage
export const loadSavedConsent = () => {
  if (localStorage && (localStorage.getItem(lsConsent) !== null)) {
    //Previous consent exists, check if it has expired or if there is a version mismatch
    const prevSessionTS = localStorage.getItem(lsConsentTS);
    const currentTS = Date.now();
    if (prevSessionTS !== null) {
      if (currentTS - prevSessionTS > 1.577e10) {  //See if its older than 6 months
        //Its expired, delete the prev session
        localStorage.removeItem(lsConsent);
        localStorage.removeItem(lsConsentTS);
        localStorage.removeItem(lsConsentVName);
        return null;
      }
    }
    const previousVersion = localStorage.getItem(lsConsentVName);
    if (previousVersion !== lsConsentVersion) {
      //Version mismatch, delete the prev session
      localStorage.removeItem(lsConsent);
      localStorage.removeItem(lsConsentTS);
      localStorage.removeItem(lsConsentVName);
      return null;
    }

    return JSON.parse(localStorage.getItem(lsConsent));
  }
  return null;
};

//Save the provided consent preferences to localStorage
export const saveConsentPreferences = (savedConsent) => {
  let sessionIDs = [];
  //Get the previous session ID array if it exists
  if (localStorage.getItem(lsConsentSessionIDs) !== null) {
    sessionIDs = JSON.parse(localStorage.getItem(lsConsentSessionIDs));
  }
  //Add a new ID
  const newSession = uniqueID();
  sessionIDs.push(newSession);

  //Save everything
  localStorage.setItem(lsConsent, JSON.stringify(savedConsent));
  localStorage.setItem(lsConsentSessionIDs, JSON.stringify(sessionIDs));
  localStorage.setItem(lsConsentTS, Date.now());
  localStorage.setItem(lsConsentVName, lsConsentVersion);

  //Save consent on server
  postRequest(
    "/api/Consent/SaveConsent",
    {
      consent_ID: newSession,
      consentDetails: JSON.stringify(savedConsent),
    },
    () => {/*ignore*/}, 
    () => {/*ignore*/},
  );
};

//This function deletes the currently saved consent
export const resetConsent = () => {
  localStorage.removeItem(lsConsent);
  localStorage.removeItem(lsConsentTS);
  localStorage.removeItem(lsConsentVName);
};

/* eslint-disable no-undef*/
/* Google Analytics API Helper
  Enable and Setup google analytics with initial config
 */
export const gtagSetup = () => {
  // if (window.gtag !== undefined) {
  //   window["ga-disable-UA-{id}"] = false;
  //   gtag("config", "UA-{id}");
  // }
};
/* eslint-enable no-undef */