import "./resources.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import BackgroundEffect from "../general/BackgroundEffect";
import StandardButton from "../general/StandardButton";

const styles = {
  pwInput: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#fff",
    width: 300,
  },
};

const Resources = () => {

  const [hasAccess, setHasAccess] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState("");

  return (
    <main>
      <Helmet>
        <title>Resources | Avior Labs</title>
        <meta
          name={"description"}
          content={"Download product documentation and other resources from Avior Labs."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"about-header"}>
        <h1>RESOURCES</h1>
        <img
          className={"about-header-img"}
          src={"/images/resources/resources-header-1.jpg"}
        />
      </header>

      <section className={"resources-section standard-width"}>
        <p className={"home-intro-1-text"}>
          Welcome to the Avior Labs Resources Portal. Here you will find a collection of product documentation
          and other resources to help you get the most out of your Avior Labs products. To request access or if you
          require additional information, please <Link to={PAGE_ROUTES.CONTACT}><u>get in touch</u></Link>.
        </p>

        {
          (!hasAccess) && (
            <React.Fragment>
              <div>
                <TextField
                  color={"secondary"}
                  label={"Access Code"}
                  variant={"filled"}
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value)}
                  style={styles.pwInput}
                />
              </div>
              <StandardButton
                label={"Unlock Resources"}
                onClick={() => {
                  if (accessCode === "AL-RESOURCES") {
                    setHasAccess(true);
                  }
                }}
                className={"resources-unlock"}
              />
            </React.Fragment>
          )
        }

        {
          (hasAccess) && (
            <div className={"resources-cont"}>
              <h3 className={"resources-head"}>Product Documentation</h3>
              <div className={"resource-item standard-box"}>
                <img src={"/images/resources/resource-icon.png"} />
                <h4>Elevation VTOL Drone General Information</h4>
                <a
                  href={"/images/resources/ElevationVTOLGeneral.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <StandardButton
                    label={"Open"}
                    className={"resource-download"}
                  />
                </a>
              </div>
              <div className={"resource-item standard-box"}>
                <img src={"/images/resources/resource-icon.png"} />
                <h4>Wildlife e-Census Game Counting Overview</h4>
                <a
                  href={"/images/resources/GameCountingOverview.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <StandardButton
                    label={"Open"}
                    className={"resource-download"}
                  />
                </a>
              </div>
              
              <h3 className={"resources-head top-space"}>General</h3>
              <div className={"resource-item standard-box"}>
                <img src={"/images/resources/resource-icon.png"} />
                <h4>Company History & Overview</h4>
                <a
                  href={"/images/resources/CompanyOverview.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <StandardButton
                    label={"Open"}
                    className={"resource-download"}
                  />
                </a>
              </div>
            </div>
          )
        }
      </section>
    </main>
  );
};

export default Resources;