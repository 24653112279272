import "./404.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const styles = {
  contactButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    marginBottom: "20px",
    textTransform: "none",
  },
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
  },
};

const Page404 = () => {
  return (
    <main>
      <Helmet>
        <title>Avior Labs</title>
        <meta
          name={"robots"}
          content={"noindex"}
        ></meta>
      </Helmet>

      <section className={"standard-width section-404"}>
        <img
          className={"img-404"}
          src={"/images/404.png"}
        />
        <p className={"text-404"}>The good news: we've recently rebuilt our website! The bad news: it looks like you've accessed an old link... Not to worry, you can find the new site below:</p>
        <Link to={"/"}>
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.homeButton}
          >
            Go to Home
          </Button>
        </Link>
      </section>
    </main>
  );
};

export default Page404;

