import "./topNav.css";

import React from "react";
import { Link } from "react-router-dom";
import { Button, IconButton, Drawer, Menu, MenuItem, Collapse } from "@material-ui/core";
import { Menu as MenuIcon, Close, ArrowDropDown, Facebook, LinkedIn } from "@material-ui/icons";

import { PAGE_ROUTES } from "../../utils/stateTypes";

// Material UI styles
const styles = {
  menuButton: {
    marginRight: "20px",
  },
  menuFeaturedButton: {
    color: "rgb(124, 124, 124)",
  },

  drawerClose: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  drawerButton: {
    width: "100%",
    padding: "15px",
  },
  socialIcon: {
    width: 20,
    height: 20,
  },
  socialButton: {
    marginRight: 3,
  },
};

//Set up a function to update isScrolled when scrolling
const handleScroll = (setIsScrolled) => (
  () => {
    const scrolled = window.scrollY > 50;
    setIsScrolled(scrolled);
  }
);


const TopNav = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const closeDrawer = React.useCallback(() => {
    setShowDrawer(false);
    setMobileSub(false);
  }, []);
  const openDrawer = React.useCallback(() => setShowDrawer(true), []);

  const [isScrolled, setIsScrolled] = React.useState(false);
  React.useEffect(() => {
    let checkIsScrolledFn = setInterval(handleScroll(setIsScrolled), 100);
    return () => {
      clearInterval(checkIsScrolledFn);
    };
  }, []);

  // Top nav submenu
  const [subAnchor, setSubAnchor] = React.useState(null);
  const openSub = React.useCallback((e) => {
    setSubAnchor(e.currentTarget);
  }
  , []);
  const closeSub = React.useCallback(() => {
    setSubAnchor(null);
  }, []);

  // Mobile submenu
  const [mobileSub, setMobileSub] = React.useState(false);
  const toggleMobileSub = React.useCallback(() => {
    setMobileSub(!mobileSub);
  }, [mobileSub]);


  return (
    <nav className={`menu-top ${isScrolled ? "scrolled" : ""}`}>
      <div className={"menu-top-inner standard-width"}>
        <Link
          to={PAGE_ROUTES.HOME}
          className={"menu-top-logo"}
        >
          <img
            src={"/images/logo-light.png"}
            className={"menu-top-logo-img"}
          />
        </Link>
        
        <div className={"menu-top-links"}>
          <Link
            to={PAGE_ROUTES.HOME}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
            >
              Home
            </Button>
          </Link>
          <span className={"menu-top-button"}>
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
              onClick={openSub}
              endIcon={<ArrowDropDown />}
            >
              DRONES
            </Button>
          </span>
          {/* Set up the submenu */}
          <Menu
            keepMounted
            anchorEl={subAnchor}
            open={Boolean(subAnchor)}
            onClose={closeSub}
            disableScrollLock={true}
          >
            <MenuItem>
              <Link to={PAGE_ROUTES.ELEVATION_S}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeSub}
                  color={"secondary"}
                >
                  Elevation S
                </Button>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={PAGE_ROUTES.ELEVATION_G}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeSub}
                  color={"secondary"}
                >
                  Elevation G
                </Button>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={PAGE_ROUTES.CALISTO}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeSub}
                  color={"secondary"}
                >
                  Callisto
                </Button>
              </Link>
            </MenuItem>
          </Menu>

          <Link
            to={PAGE_ROUTES.SOFTWARE}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
            >
              Software
            </Button>
          </Link>
          <Link
            to={PAGE_ROUTES.ABOUT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
            >
              About
            </Button>
          </Link>
          <Link
            to={PAGE_ROUTES.RESOURCES}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
            >
              Resources
            </Button>
          </Link>
          <Link
            to={PAGE_ROUTES.CONTACT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              color={"secondary"}
              style={styles.menuButton}
            >
              Contact
            </Button>
          </Link>
          <IconButton
            href={"https://www.facebook.com/aviorlabs/"}
            target={"_blank"}
            color={"secondary"}
            style={styles.socialButton}
            size={"small"}
          >
            <Facebook style={styles.socialIcon} />
          </IconButton>
          <IconButton
            href={"https://www.linkedin.com/company/avior-labs-pty-ltd"}
            target={"_blank"}
            color={"secondary"}
            style={styles.socialButton}
            size={"small"}
          >
            <LinkedIn style={styles.socialIcon} />
          </IconButton>
        </div>

        {/* =================== MOBILE MENU =================== */}
        <span className={"mobile-menu-toggle"}>
          <IconButton
            onClick={openDrawer}
            color={"secondary"}
          >
            <MenuIcon />
          </IconButton>
        </span>
      </div>

      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={closeDrawer}
      >
        <div className={"drawer-inner"}>
          <IconButton
            onClick={closeDrawer} 
            style={styles.drawerClose}
            color={"secondary"}
          >
            <Close />
          </IconButton>
          
          <div>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Home
              </Button>
            </Link>
            <Button
              variant={"text"}
              color={"secondary"}
              size={"large"}
              style={styles.drawerButton}
              onClick={toggleMobileSub}
              endIcon={<ArrowDropDown />}
            >
              Drones
            </Button>
            <Collapse
              in={mobileSub}
              timeout={"auto"}
              unmountOnExit
            >
              <Link to={PAGE_ROUTES.ELEVATION_S}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Elevation S
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.ELEVATION_G}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Elevation G
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.CALISTO}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Callisto
                </Button>
              </Link>
            </Collapse>
            <Link to={PAGE_ROUTES.SOFTWARE}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Software
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.ABOUT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                About
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.RESOURCES}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Resources
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.CONTACT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Contact
              </Button>
            </Link>
          </div>

          <div className={"mobile-menu-bottom"}>
            <div className={"mobile-menu-socials"}>
              <IconButton
                href={"https://www.facebook.com/aviorlabs/"}
                target={"_blank"}
                color={"secondary"}
                style={styles.socialButton}
              >
                <Facebook style={styles.socialIcon} />
              </IconButton>
              <IconButton
                href={"https://www.linkedin.com/company/avior-labs-pty-ltd"}
                target={"_blank"}
                color={"secondary"}
                style={styles.socialButton}
              >
                <LinkedIn style={styles.socialIcon} />
              </IconButton>
            </div>
            <img
              className={"drawer-menu-logo"}
              src={"/images/logo-light.png"}
            />
          </div>
        </div>
      </Drawer>
    </nav>
  );
};

export default TopNav;