import "./contact.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@material-ui/core";

import { LOADING_STATE } from "../../utils/stateTypes";
import BackgroundEffect from "../general/BackgroundEffect";

const styles = {
  inputHalf: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    marginRight: 20,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  inputHalf2: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  input: {
    marginBottom: 20,
    textAlign: "left",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  menuItem: {
    color: "#fff",
  },
  submit: {
    padding: 10,
    height: 50,
  },
};

const sendMessage = async (name, email, type, phone, message, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      type,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const Contact = () => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("Arrange a demo");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }


  return (
    <main>
      <Helmet>
        <title>Contact | Avior Labs</title>
        <meta
          name={"description"}
          content={"Get in touch with Avior Labs to arrange a demo, request information, or ask a question."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"about-header"}>
        <h1>CONTACT</h1>
        <img
          className={"about-header-img"}
          src={"/images/contact/contact-header.jpg"}
        />
      </header>

      <section className={"standard-width contact-form-section"}>
        <h2>Say hello</h2>
        <form
          className={"contact-form"}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(name, email, type, phone, message, setSendState);
          }}
        >
          <TextField
            label={"Name"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label={"Email"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label={"Phone number"}
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <FormControl
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
          >
            <InputLabel>I am enquiring to</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
              color={"primary"}
            >
              <MenuItem
                value={"Arrange a demo"}
                style={styles.menuItem}
              >
                Arrange a demo
              </MenuItem>
              <MenuItem
                value={"General enquiry"}
                style={styles.menuItem}
              >
                  General enquiry
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Please briefly describe your requirements"}
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
            multiline
            minRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <Button
            variant={"contained"}
            color={"primary"}
            type={"submit"}
            fullWidth
            style={styles.submit}
            disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </form>
      </section>

      <section className={"standard-width contact-details-section"}>
        <div className={"contact-details"}>
          <h2>Contact</h2>
          <table>
            <tbody>
              <tr>
                <td><b>Phone</b></td>
              </tr>
              <tr>
                <td>+27 87 821 3240</td>
              </tr>
              <tr>
                <td><b>Email</b></td>
              </tr>
              <tr>
                <td>drones<span style={{ display: "none" }}>botblocker</span>@aviorlabs.com</td>
              </tr>
              <tr>
                <td><b>Address</b></td>
              </tr>
              <tr>
                <td>B-102 De Goedehoop Close, 121 Sovereign Drive, Route 21 Business Park, Irene, 0157, South Africa</td>
              </tr>
            </tbody>
          </table>
        </div>

        <iframe
          className={"map standard-box"}
          src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.3686061036706!2d28.254940076722324!3d-25.857342977291996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9567ac3c74f935%3A0x3446b9d1a8965b7!2s121%20Sovereign%20Dr%2C%20Route%2021%20Business%20Park%2C%20Centurion%2C%200178%2C%20South%20Africa!5e0!3m2!1sen!2snl!4v1724763588571!5m2!1sen!2snl"}
          allowFullScreen={true}
          loading={"lazy"}
          referrerPolicy={"no-referrer-when-downgrade"}
        />
      </section>
    </main>
  );
};

export default Contact;