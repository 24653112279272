/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-max-props-per-line */
import "./footer.css";

import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";


import { PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  socialButton: {
    margin: "0 5px",
  },
  socialIcon: {
    width: 22,
    height: 22,
  },
  navButton: {
    display: "block",
    padding: "6px 5px",
    marginBottom: 5,
    textAlign: "left",
    minWidth: "100%",
  },
};


const Footer = () => {
  // Submenu
  const [subAnchor, setSubAnchor] = React.useState(null);
  const openSub = React.useCallback((e) => {
    setSubAnchor(e.currentTarget);
  }
  , []);
  const closeSub = React.useCallback(() => {
    setSubAnchor(null);
  }, []);

  return (
    <footer>
      <div className={"standard-width"}>
        <div className={"footer-top"}>
          <div className={"footer-left"}>
            <Link to={PAGE_ROUTES.HOME}>
              <div className={"footer-logo-cont"}>
                <img
                  className={"footer-logo"}
                  src={"/images/logo-light.png"}
                />
              </div>
            </Link>

            <p className={"footer-about"}>
              Avior Labs is an aerospace company that designs, develops, and manufactures
              unmanned aerial vehicles (UAV) and associated solutions for commercial and humanitarian applications.
            </p>
          </div>

          <div className={"footer-right"}>
            <div className={"footer-right-nav"}>
              <h3 className={"footer-head"}>Navigation</h3>
              <Link to={PAGE_ROUTES.HOME}>
                <Button
                  color={"secondary"}
                  style={styles.navButton}
                >
                  Home
                </Button>
              </Link>
              <Button
                color={"secondary"}
                style={styles.navButton}
                onClick={openSub}
              >
                Drones
              </Button>
              {/* Set up the submenu */}
              <Menu
                keepMounted
                anchorEl={subAnchor}
                open={Boolean(subAnchor)}
                onClose={closeSub}
                disableScrollLock={true}
              >
                <MenuItem>
                  <Link to={PAGE_ROUTES.ELEVATION_S}>
                    <Button
                      variant={"text"}
                      style={styles.menuButton}
                      onClick={closeSub}
                      color={"secondary"}
                    >
                      Elevation S
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={PAGE_ROUTES.ELEVATION_G}>
                    <Button
                      variant={"text"}
                      style={styles.menuButton}
                      onClick={closeSub}
                      color={"secondary"}
                    >
                      Elevation G
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={PAGE_ROUTES.CALISTO}>
                    <Button
                      variant={"text"}
                      style={styles.menuButton}
                      onClick={closeSub}
                      color={"secondary"}
                    >
                      Callisto
                    </Button>
                  </Link>
                </MenuItem>
              </Menu>
              <Link to={PAGE_ROUTES.SOFTWARE}>
                <Button
                  color={"secondary"}
                  style={styles.navButton}
                >
                  Software
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.ABOUT}>
                <Button
                  color={"secondary"}
                  style={styles.navButton}
                >
                  About
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.CONTACT}>
                <Button
                  color={"secondary"}
                  style={styles.navButton}
                >
                  Contact
                </Button>
              </Link>
            </div>
            <div className={"footer-right-contact"}>
              <h3 className={"footer-head"}>Contact</h3>
              <Link to={PAGE_ROUTES.CONTACT}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.navButton}
                >
                  drones<span style={{ display: "none" }}>botblocker</span>@aviorlabs.com
                </Button>
              </Link>
              <Button
                variant={"text"}
                color={"secondary"}
                href={"tel:+27 87 821 3240"}
                style={styles.navButton}
              >
                +27 87 821 3240
              </Button>
              <Button
                variant={"text"}
                color={"secondary"}
                href={"https://maps.app.goo.gl/HLgGmhhts18Yax2s8"}
                target={"_blank"}
                style={styles.navButton}
              >
                B-102 De Goedehoop Close, 121 Sovereign Drive, Route 21 Business Park, Irene, 0157, South Africa
              </Button>
            </div>
          </div>
        </div>

        <div className={"footer-divider"} />

        <Button
          variant={"text"}
          color={"secondary"}
          href={"/images/Privacy_notice.pdf"}
          target={"_blank"}
        >
          Privacy Policy
        </Button>
        <Button
          variant={"text"}
          color={"secondary"}
          href={"/images/POPIA_manual.pdf"}
          target={"_blank"}
        >
          POPIA Manual
        </Button>
        

        <p className={"footer-legal"}>
          Site built by <a href={"https://tap-x.com"} target={"_blank"}>TapX</a> | Icons by <a href={"https://www.flaticon.com/authors/freepik"} target={"_blank"}>Freepik</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

