/* eslint-disable react-hooks/exhaustive-deps */
import "./software.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@material-ui/core";

import { LOADING_STATE } from "../../utils/stateTypes";
import BackgroundEffect from "../general/BackgroundEffect";
import Blip from "../drones/Blip";
import ComponentBlock from "../drones/ComponentBlock";
import IndustriesCarousel from "../home/IndustriesCarousel";

const styles = {
  inputHalf: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    marginRight: 20,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textAlign: "left",
  },
  inputHalf2: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textAlign: "left",
  },
  input: {
    marginBottom: 20,
    textAlign: "left",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  menuItem: {
    color: "#fff",
  },
  submit: {
    padding: 10,
    height: 50,
  },
};

const INTRO_GAL = [
  {
    img: "/images/software/gal-1.jpg",
    label: "Thermal & Visual Imaging",
  },
  {
    img: "/images/software/gal-3.jpg",
    label: "Trained neural network",
  },
  {
    img: "/images/software/gal-2.jpg",
    label: "Mapping & record keeping",
  },
];

const sendMessage = async (name, company, email, province, phone, purpose, size, drone, message, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendSoftwareMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      company,
      province,
      purpose,
      size,
      drone,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const Software = () => {

  //Set up intro gallery
  const [galIndex, setGalIndex] = React.useState(0);
  //Auto rotate images
  const [intervalItem, setIntervalItem] = React.useState(null);
  React.useEffect(() => {
    let intervalLength = 5000;
    if (intervalItem) {
      clearInterval(intervalItem);
    }
    else {
      //First or manually set, longer interval
      intervalLength = 10000;
    }
    setIntervalItem(setInterval(() => {
      setGalIndex((galIndex + 1) % INTRO_GAL.length);
    }, intervalLength));
    return () => {
      clearInterval(intervalItem);
    };
  }, [galIndex]);

  //Clear interval when manually changing image
  const manuallyChangeImage = (i) => {
    clearInterval(intervalItem);
    setIntervalItem(null);
    setGalIndex(i);
  };

  //Set up the contact form
  const [name, setName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [size, setSize] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [drone, setDrone] = React.useState("I need a drone & an operator");
  const [purpose, setPurpose] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }


  return (
    <main>
      <Helmet>
        <title>Software | Avior Labs</title>
        <meta
          name={"description"}
          content={"Wildlife e-Census is an AI-based solution that in conjunction with images collected with drones provides accurate and repeatable game counts."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"about-header"}>
        <img
          className={"software-logo"}
          src={"/images/software/logo.png"}
        />
        <img
          className={"about-header-img"}
          src={"/images/software/software-header9.jpg"}
        />
      </header>

      <section className={"drone-intro-section standard-width"}>
        <h2 className={"section-head"}>
          Informed wildlife management
        </h2>
        <p className={"home-intro-1-text"}>
          Game counting is a crucial aspect of wildlife management. This information allows
          managers to make informed decisions to maintain ecological balance and protect the
          various species on the property. Wildlife e-Census is an AI-based solution that in
          conjunction with images collected with drones
          provides <u>accurate and repeatable</u> game counts.
        </p>
        <div className={"drone-intro-gal"}>
          <div className={"drone-intro-gal-main-cont"}>
            {
              INTRO_GAL.map((gal, i) => (
                <img
                  className={`drone-intro-gal-main standard-box ${galIndex === i ? "active" : ""}`}
                  src={gal.img}
                  key={i}
                />
              ))
            }
          </div>
          <div className={"drone-intro-gal-thumbs"}>
            <h3>{INTRO_GAL[galIndex].label}</h3>
            {
              INTRO_GAL.map((gal, i) => (
                <div
                  className={`drone-intro-gal-thumb-cont ${galIndex === i ? "active" : ""}`}
                  onClick={() => manuallyChangeImage(i)}
                  key={i}
                >
                  <img
                    className={"drone-intro-gal-thumb standard-box"}
                    src={gal.img}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className={"software-explain-section"}>
        <div className={"software-explain-bg"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>The problem</h2>
          <div className={"software-problem-cont"}>
            <p>
              Counting of wild animals has traditionally been a difficult and expensive task. At Avior Labs we started by researching what the main challenges were with the current methods of game counting:
            </p>
            <ul>
              <li>&#8226; Wild animals cannot be herded like domestic animals</li>
              <li>&#8226; It is not possible to come too close to some wild animals due to them being dangerous</li>
              <li>&#8226; Wild animals are well camouflaged and not very easy to see</li>
              <li>&#8226; Some wild animal species are nocturnal and very difficult to find during the day</li>
              <li>&#8226; Wild animals are often found in groups making it difficult to count individuals</li>
            </ul>
            <img
              className={"software-problem-img standard-box"}
              src={"/images/software/problem-2.jpg"}
            />
          </div>

          <h2 className={"section-head"}>Our Solution</h2>

          <div className={"software-problem-cont"}>
            <p>
              Wildlife e-Census involves processing and analysing of data extracted from images gathered by drones. The driving purpose
              of our solution is to make game counting more <u>accurate, repeatable, and cost-effective</u>.<br /><br />The main components of the solution are:
            </p>
            <ul>
              <li>&#8226; A collection of thermal and visual image pairs following Avior Labs’ proprietary procedure</li>
              <li>&#8226; Processing of images by Avior Labs’ proprietary AI-driven algorithms to locate and  categorise animals</li>
              <li>&#8226; Automatic counting of categorised animals </li>
              <li>&#8226; Comprehensive automated reporting</li>
            </ul>
            <img
              className={"software-problem-img standard-box"}
              src={"/images/software/software-explain-2.jpg"}
            />
          </div>

          <h3>Benefits of using UAVs for game counting</h3>
          <table className={"software-compare-table standard-box"}>
            <thead>
              <tr>
                <th>
                  <p>UAV with Wildlife e-Census</p>
                  <img
                    className={"software-compare-head-img"}
                    src={"/images/software/benefits-elevation.png"}
                  />
                </th>
                <th>
                  <p>Conventional Method (Helicopter or small aircraft surveys)</p>
                  <img
                    className={"software-compare-head-img"}
                    src={"/images/software/benefits-helicopter.png"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>Accurate & repeatable</span> estimation of the numer of animals per species
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "90%" }}
                  />
                </td>
                <td>
                  Manual counting is <span>difficult and innacurate</span>, and can not be repeated easily
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "30%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Using drones <span>considerably more cost-effective</span>
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "80%" }}
                  />
                </td>
                <td>
                  The cost of operating a helicopter of small aircraft is often <span>unaffordable</span>
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "25%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  The operator is <span>safe</span> and comfortable
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "90%" }}
                  />
                </td>
                <td>
                  Low altitude flying is <span>dangerous</span> for personnel and disturbing to the game
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "55%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  AI does the bulk of the work - <span>no need to pay for highly expensive man-hours</span>
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "70%" }}
                  />
                </td>
                <td>
                  Manual game counting is <span>labour intensive</span> and can be both costly and <span>time consuming</span>
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "25%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Drones are small and <span>quiet</span>, especially Elevation-S with SilentFly™ technology
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "80%" }}
                  />
                </td>
                <td>
                  <span>High noise levels</span> or disruptive movements can cause game to run and thus affect the count accuracy
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "32%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  A timeous <span>automated report</span> can effectively inform management decisions
                  <div
                    className={"comparison-bar good"}
                    style={{ width: "95%" }}
                  />
                </td>
                <td>
                  Manual counts and manual reporting make it <span>difficult to detect trends</span>
                  <div
                    className={"comparison-bar bad"}
                    style={{ width: "45%" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h3>How does it work?</h3>

          <div className={"software-blips-cont"}>
            <img
              className={"software-blips-img standard-box"}
              src={"/images/software/how-it-works-2.jpg"}
            />
            <Blip
              head={"Dual imaging system"}
              text={"Infrared and visual pictures are taken simulaneously as the UAV flies over the area."}
              x={"25%"}
              y={"45%"}
              right={true}
              top={true}
              number={"1"}
            />
            <Blip
              head={"Counting and reporting"}
              text={"Stats from the identification and categorisation process are analysed to produce a count, which is depicted in a report with additional information such as maps, process, etc. If previous counts were conducted, the data is reported with historic information for trend analysis"}
              x={"35%"}
              y={"75%"}
              right={true}
              top={true}
              number={"4"}
            />
            <Blip
              head={"Accurate detection"}
              text={"The infrared imagery helps to identify animals that could be missed by the naked eye due to light or vegetation."}
              x={"65%"}
              y={"15%"}
              right={false}
              top={false}
              altColor={true}
              number={"2"}
            />
            <Blip
              head={"Identification and categorisation"}
              text={"Neural network trained on thousands of images of animals identifies and categorises animals eliminating a human error usually involved in manual counts"}
              x={"78%"}
              y={"52%"}
              right={false}
              top={false}
              altColor={true}
              number={"3"}
            />
          </div>
          {/* <div className={"software-explain-cont"}>
            <img
              className={"software-explain-img"}
              src={"/images/software/software-explain-1.png"}
            />
            <div className={"software-explain-connector"}>
              <div className={"dot"} />
              <div className={"line"} />
            </div>
            <div className={"software-explain-text"}>
              <p>
                Infrared and visual pictures are taken simulaneously as the UAV flies over the area.
              </p>
            </div>
          </div>

          <div className={"software-explain-cont"}>
            <div className={"software-explain-text hide-when-sm"}>
              <p>
                The infrared imagery helps to identify animals that could be missed by the naked eye due to light or vegetation.
              </p>
            </div>
            <div className={"software-explain-connector alt hide-when-sm"}>
              <div className={"dot"} />
              <div className={"line"} />
            </div>
            <img
              className={"software-explain-img"}
              src={"/images/software/software-explain-2.jpg"}
            />
            <div className={"software-explain-connector show-when-sm"}>
              <div className={"dot"} />
              <div className={"line"} />
            </div>
            <div className={"software-explain-text show-when-sm"}>
              <p>
                The infrared imagery helps to identify animals that could be missed by the naked eye due to light or vegetation.
              </p>
            </div>
          </div>

          <div className={"software-explain-cont"}>
            <img
              className={"software-explain-img"}
              src={"/images/software/software-explain-3.jpg"}
            />
            <div className={"software-explain-connector"}>
              <div className={"dot"} />
              <div className={"line"} />
            </div>
            <div className={"software-explain-text"}>
              <p>
                AI is used to quickly and accurately identify and count the animals.
              </p>
            </div>
          </div> */}
        </div>
      </section>

      <section className={"software-benefits-section"}>
        <img
          className={"software-benefits-bg"}
          src={"/images/software/benefits-bg.jpg"}
        />
        <div className={"software-benefits-bg-color"} />

        <div className={"drone-features-content standard-width"}>
          <p className={"section-pre-head"}>Turnkey solution</p>
          <h2 className={"section-head"}>Wildlife e-Census with Elevation-S</h2>

          <div className={"drone-features-blocks"}>
            <div className={"drone-features-block standard-box"}>
              <h3>Seamless integration</h3>
              <div className={"divider small"} />
              <p>
                Elevation-S is designed to work seamlessly with Wildlife e-Census. The drone makes use of our
                proprietary SilentFly™ technology, making it the perfect platform for gathering of imagery
                without disturbing animals. Undisturbed animals leads to a higher accuracy count. The long-range
                capabilities means game counts can be done in a fraction of the time compared to other drones, which
                further improves the accuracy of such exercise and reduces its costs
              </p>
            </div>
            <div className={"drone-features-block standard-box"}>
              <h3>Greater efficiency</h3>
              <div className={"divider small"} />
              <p>
                Comparison for 1000 ha aerial survey
              </p>
              <table className={"software-benefits-table"}>
                <thead>
                  <tr>
                    <th>Fixed-wing<br />(Avior Lab’s Elevation)</th>
                    <th></th>
                    <th>Multicopter<br />(DJI Enterprise 2 Advance)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>4</td>
                    <td><b>Flights</b></td>
                    <td>28</td>
                  </tr>
                  <tr>
                    <td>357 min @ 14 m/s</td>
                    <td><b>Survey time</b></td>
                    <td>682 min @ 8 m/s</td>
                  </tr>
                  <tr>
                    <td>90 min</td>
                    <td><b>Max endurance</b></td>
                    <td>25 min</td>
                  </tr>
                  <tr>
                    <td>Low – quiet</td>
                    <td><b>Noise pollution</b></td>
                    <td>High – noisy</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className={"drone-features-block standard-box"}>
              <h3>Trend analysis</h3>
              <div className={"divider small"} />
              <p>
                The data gathered can be easily compared to the previous counts allowing for a more in-depth trend analysis. Maps
                that come with such counts also allow a game manager to see the changes in concentrations of animals throughout
                the reserve.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={"software-explain-section"}>
        <div className={"software-explain-bg"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Wildlife e-Census data features</h2>
          <ComponentBlock
            image={"/images/software/icon-records.png"}
            head={"Historical record keeping"}
            text={"E-Census keeps a record of all game counts, allowing for easy comparison of game numbers over time. This is crucial for game management and identification of trends."}
            altStyle={true}
          />
          <ComponentBlock
            image={"/images/software/icon-location.png"}
            head={"Mapping of animal locations"}
            text={"E-Census provides a map of where each animal was detected. This can be used to identify areas where animals are congregating or to identify areas where animals are not being detected. Images are geotagged for easy reference."}
            altStyle={false}
          />
          <ComponentBlock
            image={"/images/software/icon-report.png"}
            head={"Comprenhensive reporting"}
            text={"E-Census provides a detailed report of the game count. This includes the total number of each species, the total number of animals, and the locations of each animal. Reports are generated automatically and can be exported in various formats."}
            altStyle={true}
          />

          <div className={"software-applications-cont"}>
            <IndustriesCarousel
              head={"Applications"}
              slides={[
                {
                  head: "Wildlife Management",
                  img: "/images/software/applications-1.jpg",
                  description: "Wildlife e-Census is the missing puzzle piece in wildlife management. By providing accurate and repeatable game counts, Wildlife e-Census enables wildlife managers to make informed conservation decisions.",
                },
                {
                  head: "Quota Management",
                  img: "/images/software/applications-2.jpg",
                  description: "Wildlife e-Census is a powerful tool for quota management. By providing accurate game counts, Wildlife e-Census enables managers to set quotas that are sustainable and protect the wildlife population on the property.",
                },
                {
                  head: "Property Evaluation",
                  img: "/images/software/applications-3.jpg",
                  description: "When paired with the long-range capabilities of Elevation-S, Wildlife e-Census allows you to quickly and accurately evaluate the wildlife population on a property. This information is crucial for making informed decisions about the property and its market value.",
                },
                {
                  head: "Insurance",
                  img: "/images/software/applications-4.jpg",
                  description: "By providing accurate and repeatable game counts, Wildlife e-Census enables owners and insurance companies to accurately assess the value of their assets and ensure that these are protected against possible risks.",
                },
              ]}
            />
          </div>
        </div>
      </section>

      {/* ================================== CTA ================================== */}
      <section className={"drone-cta-section standard-width"}>
        <p className={"section-head-pre"}>Contact us</p>
        <h2 className={"section-head"}>Get your count</h2>
        <p className={"drone-cta-text"}>
          Get in touch with us for your Wildlife e-Census. We will show you how the system works and how it can be an asset in your wildlife reserve operations.
        </p>
        <form
          className={"contact-form"}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(name, company, email, province, phone, purpose, size, drone, message, setSendState);
          }}
        >
          <TextField
            label={"Name"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label={"Company name"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf2}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
          <FormControl
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
            required
          >
            <InputLabel>Province</InputLabel>
            <Select
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem
                value={"Gauteng"}
                style={styles.menuItem}
              >
                Gauteng
              </MenuItem>
              <MenuItem
                value={"Western Cape"}
                style={styles.menuItem}
              >
                Western Cape
              </MenuItem>
              <MenuItem
                value={"Eastern Cape"}
                style={styles.menuItem}
              >
                Eastern Cape
              </MenuItem>
              <MenuItem
                value={"KwaZulu-Natal"}
                style={styles.menuItem}
              >
                KwaZulu-Natal
              </MenuItem>
              <MenuItem
                value={"Mpumalanga"}
                style={styles.menuItem}
              >
                Mpumalanga
              </MenuItem>
              <MenuItem
                value={"Limpopo"}
                style={styles.menuItem}
              >
                Limpopo
              </MenuItem>
              <MenuItem
                value={"North West"}
                style={styles.menuItem}
              >
                North West
              </MenuItem>
              <MenuItem
                value={"Free State"}
                style={styles.menuItem}
              >
                Free State
              </MenuItem>
              <MenuItem
                value={"Northern Cape"}
                style={styles.menuItem}
              >
                Northern Cape
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Email"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label={"Contact number"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf2}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <FormControl
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
            required
          >
            <InputLabel>Counting purpose</InputLabel>
            <Select
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem
                value={"Game management"}
                style={styles.menuItem}
              >
                Game management
              </MenuItem>
              <MenuItem
                value={"Insurance"}
                style={styles.menuItem}
              >
                Insurance
              </MenuItem>
              <MenuItem
                value={"Property valuation"}
                style={styles.menuItem}
              >
                roperty valuation
              </MenuItem>
              <MenuItem
                value={"Other"}
                style={styles.menuItem}
              >
                Other
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Survey area (ha)"}
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf}
            value={size}
            onChange={(e) => setSize(e.target.value)}
            required
          />
          <FormControl
            variant={"filled"}
            color={"secondary"}
            style={styles.inputHalf2}
            required
          >
            <InputLabel>Drone status</InputLabel>
            <Select
              value={drone}
              onChange={(e) => setDrone(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem
                value={"I need a drone & an operator"}
                style={styles.menuItem}
              >
                I need a drone & an operator
              </MenuItem>
              <MenuItem
                value={"I have a drone but need an operator"}
                style={styles.menuItem}
              >
                I have a drone but need an operator
              </MenuItem>
              <MenuItem
                value={"I have a drone & an operator"}
                style={styles.menuItem}
              >
                I have a drone & an operator
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Please briefly describe your requirements"}
            variant={"filled"}
            color={"secondary"}
            style={styles.input}
            fullWidth
            multiline
            minRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <Button
            variant={"contained"}
            color={"primary"}
            type={"submit"}
            fullWidth
            style={styles.submit}
            disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </form>
      </section>
    </main>
  );
};

export default Software;