import "./specs.css";

import React from "react";


const Specs = ({ specItems, image, altStyle=false }) => {

  return (
    <div className={"home-specs"}>
      <div className={["home-spec", altStyle ? "alt" : ""].join(" ")}>
        <div className={"home-spec-img-cont standard-box"}>
          <img
            src={image}
            className={"home-spec-img"}
          />
        </div>
        <div className={"home-spec-content"}>
          <div className={"home-spec-content-bg"} />
          <div className={"home-spec-content-bg-border"} />
          <img
            className={"home-spec-head-icon"}
            src={"/images/home/icon-specs.png"}
          />
          <h3>Key specifications</h3>
          {
            specItems.map((item, i) => (
              <div
                key={i}
                className={"home-spec-item"}
              >
                <img
                  className={"home-spec-item-icon"}
                  src={item.icon}
                />
                <p>{item.text}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Specs;