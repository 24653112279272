/* eslint-disable react-hooks/exhaustive-deps */
import "./css/normalize.css"; //CSS reset
import "./css/site.css";

import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { PAGE_ROUTES } from "../utils/stateTypes";
import Page404 from "./home/404";
import ScrollToTop from "./general/ScrollToTop";
import PageTransition from "./general/PageTransition";
import TopNav from "./home/TopNav";
import Footer from "./home/Footer";
import Home from "./home/Home";
import ElevationS from "./drones/ElevationS";
import ElevationG from "./drones/ElevationG";
import Calisto from "./drones/Calisto";
import About from "./about/About";
import Resources from "./resources/Resources";
import Contact from "./contact/Contact";
import Software from "./software/Software";
import ConsentBanner from "./consent/ConsentBanner";


const App = () => {
  let location = useLocation();

  return (
    <div>
      <ScrollToTop />
      <PageTransition />
      <TopNav />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"fade"}
          timeout={1000}
        >
          <Switch location={location}>
            <Route
              exact
              path={PAGE_ROUTES.HOME}
              component={Home}
            />
            <Route
              exact
              path={PAGE_ROUTES.ELEVATION_S}
              component={ElevationS}
            />
            <Route
              exact
              path={PAGE_ROUTES.ELEVATION_G}
              component={ElevationG}
            />
            <Route
              exact
              path={PAGE_ROUTES.CALISTO}
              component={Calisto}
            />
            <Route
              exact
              path={PAGE_ROUTES.SOFTWARE}
              component={Software}
            />
            <Route
              exact
              path={PAGE_ROUTES.ABOUT}
              component={About}
            />
            <Route
              exact
              path={PAGE_ROUTES.RESOURCES}
              component={Resources}
            />
            <Route
              exact
              path={PAGE_ROUTES.CONTACT}
              component={Contact}
            />
            <Route
              component={Page404}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
      <ConsentBanner />
    </div>
  );
};

export default App;